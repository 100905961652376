<template>
  <div class="result-section">
    <div class="row">
      <div class="col-12 result-title mb-3">
        <slot name="result-title" />
      </div>
      <div class="col-12 result-document" v-if="is_loading">
        <slot name="result-loging">
          <PocLoading />
        </slot>
      </div>
      <div class="col-12">
        <slot name="result-detail" />
      </div>
    </div>    
  </div>
</template>

<script>
import PocLoading from "@/components/PocLoading.vue";
export default {
  components: { PocLoading },
  name: "BaseResult",
  props: {
    is_loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .result-section::v-deep {
    border: 2px solid #40E0D0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 18px 32px;
    background-color: #FFFFFF;
    overflow-y: scroll;
  }

  .result-section::v-deep .result-title{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    color:#000000;
  }
  .result-section::v-deep .result-document{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 250%;
    color: #000000;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 585px;
  }
</style>