<template>
  <div class="spinner-border poc-loading" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</template>

<script>
export default {
  name: 'PocLoading',
}
</script>

<style>
.spinner-border.poc-loading {
  width: 64px;
  height: 64px;
  border-color: #01dab5;
  border-right-color: transparent;
}
</style>