<template>
  <BaseTemplate>
    <template slot="header-content">
      <div class="col-12 description-section">
        <div class="title">English Speak Up</div>
        <div class="description">
          Learning foreign languages for Thai learners is sometimes a one way learning process. There is no feedback loop for learners which is a crucial part of learning. According to our potential NLP, we therefore seek to purposefully use a speech recognition system to listen to Thai learners and provide them with useful feedback so they can effectively analyze and enhance their own learning performance. 
        </div>
      </div>
    </template>
    <template slot="input-content-center">
      <div class="d-grid gap-2 d-flex mb-3">
        <div class="input-group input-group-lg">
          <input type="text" class="form-control text-input demo-bd-danger" v-model="text_input"/>
        </div>
        <ButtonComponent 
          class="demo-bg-danger-light demo-t-secondary" icon="volume_up" text=""
          @click.native="clickRead()"/>
      </div>
      <div class="d-grid gap-2 d-md-flex">
        <ButtonComponent 
          class="demo-bg-danger w-100" icon="mic" text="Speak"
          v-if="!is_record"
          @click.native="clickSpeak()"/>
        <ButtonComponent 
          class="demo-bg-secondary w-100" icon="stop_circle" text="Stop"
          v-if="is_record"
          @click.native="clickStopSpeak()"/>
      </div>
    </template>
    <template slot="output-content">
      <!-- <LPRResult ref="result" :is_loading="is_loading"></LPRResult> -->
      <BaseResult :is_loading="is_loading" style="height: 653px;">
        <template slot="result-detail" v-if="results.data">
          <div class="text-center">
            <audio ref="player" controls class="mb-3">
              <source :src="results.audioUrl" type="audio/wav">
              Your browser does not support the audio element.
            </audio>
          </div>
          <div class="col-12">
            <p class="mb-3">
              <span class="h5 fw-bold me-2">SCORE: </span>
              <span class="h5 fw-bold" 
                :class="{ 'demo-t-danger': results.data.score !== 5, 'demo-t-primary': results.data.score === 5}"
              >{{ results.data.score }}/5</span>
            </p>
            <p class="mb-3">
              <span class="h5 fw-bold me-2">COMPUTER HEARS: </span>
              <span class="h5 fw-bold demo-t-danger">
                {{ results.data.t ? results.data.t : "NULL" }}
              </span>
            </p>
            <p class="mb-3">
              <span class="h5 fw-bold me-2">CONFIDENCE: </span>
              <span class="h5 fw-bold"
                :class="{ 'demo-t-danger': results.data.c < 80, 'demo-t-primary': results.data.c >= 80}"
              >{{ results.data.c }}%</span>
            </p>
          </div>  
        </template>
      </BaseResult>
    </template>
  </BaseTemplate>
</template>

<script>
import BaseTemplate from '@/templates/BaseTemplate.vue';
import ButtonComponent from '@/components/button/ButtonComponent.vue';
import BaseResult from '@/components/result/BaseResult.vue';
import axios from 'axios'
import audioBufferToWav from 'audiobuffer-to-wav'

export default {
  components: { 
    BaseTemplate,
    BaseResult,
    ButtonComponent
  },
  name: 'EsuHome',
  data() {
    return {
      is_loading: false,
      is_record: false,
      text_input: "I like banana",
      results: {
        mediaRecorder: null,
        gumStream: null,
        audioChunks: [],
        audioUrl: "",
        data: null,
      },
      
    }
  },
  methods:{
    clickRead(){
      if(this.text_input !== ""){
        console.log(this.text_input)
        let utterance = new SpeechSynthesisUtterance(this.text_input);
        speechSynthesis.speak(utterance);
      }
    },
    clickStopSpeak(){
      console.log("Recording stoped");
      this.results.mediaRecorder.stop();
      this.results.gumStream.getTracks() // get all tracks from the MediaStream
        .forEach( track => track.stop() ); // stop each of them
      this.is_record = false
    },
    clickSpeak(){

      console.log("recordButton clicked");

      this.results = {
        mediaRecorder: null,
        gumStream: null,
        audioChunks: [],
        audioUrl: "",
        data: null,
      }


      const self = this;

      /*
        Simple constraints object, for more advanced audio features see
        https://addpipe.com/blog/audio-constraints-getusermedia/
      */
        
      let constraints = { audio: true, video:false }

      self.is_record = true

      /*
          We're using the standard promise based getUserMedia() 
          https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
      */
      navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {
        console.log("getUserMedia() success, stream created, initializing MediaRecorder...");

        self.results.gumStream = stream
        self.results.mediaRecorder = new MediaRecorder(stream);
        self.results.mediaRecorder.start();

        console.log("Recording started");

        self.results.mediaRecorder.addEventListener("dataavailable", event => {
          self.results.audioChunks.push(event.data);
        });

        self.results.mediaRecorder.addEventListener("stop", () => {
          console.log("Create Audio blob");
          const audioBlob = new Blob(self.results.audioChunks);
          const audioUrl = URL.createObjectURL(audioBlob);          
          self.uploadSound(audioUrl)          
        });

      }).catch(function(err) {
          console.log(err)
      });
    },
    uploadSound(blob_url){
      const self = this
      self.is_loading = true

      axios.get(blob_url,{
        responseType: 'arraybuffer'
      })
      .then(res => {
        console.log("Convert Audio blob to wav blob");
        let context = new AudioContext()                  
        return context.decodeAudioData(res.data)
      })
      .then( buffer => {
        return new Blob([audioBufferToWav(buffer)], {type: "audio/wav"})
      })
      .then( blob => {
        self.results.audioUrl = URL.createObjectURL(blob)

        console.log("Upload Data");
        const url = 'https://demo.loolootech.com/aksorn/upload.php'
        let filename = new Date().toISOString();

        let fd = new FormData();
        fd.append("audio_data", blob, filename+'.wav');
        fd.append("text", this.text_input);
        return axios.post(url,fd,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      })
      .then(res => {
        self.results.data = res.data,

        console.log("get Result", self.results);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(()=>{
        self.is_loading = false
        self.$refs.player.load()
      })
    }
  },
}
</script>

<style>
  .text-input {
    border-radius: 20px !important;
  }
</style>
